<template>
  <main>
    <div
      class="container home-content"
      :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
    >
      <h2
        class="gift-code-heading medium parent-family"
        v-if="giftCodeError.error">
        {{ giftCodeError.error.message }}
      </h2>

      <router-link
        :to="{name: 'Index'}"
        class="btn"
      >
        TOP画面へ
        <ChevronRightIcon size="24"/>
      </router-link>
    </div>
  </main>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'

export default {
  name: 'Index',

  mixins: [Common],

  components: {
    ChevronRightIcon
  },

  computed: {
    ...mapState('auth', ['profile']),
    ...mapGetters('giftCode', ['giftCodeError']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_giftcode.scss';

.gift-code {
  &-heading {
    margin-bottom: 96px;
  }
}
</style>
